export const normalizedColors = {
    lightColor: "#142C39",
    blackMobile: "#121212",
    darkColor: "#142127",
    colorMobile: "#00ffff",
    whiteMobile: "#fafafa",
    transparent: 'transparent',
    redColor: '#ff0000',
    colorContrast: '#197888',
    defaultGray: '#858585',
    contrastColor: '#116473',
    black: '#000000',

    promise: '#F0D472',
    hope: '#A7D251',
    success: '#0CB64A',

    positionPor: '#FD5F85',
    positionDef: '#46C3FF',
    positionMed: '#D4D3FF',
    positionDel: '#FF964A',

    semaphoreRed: '#FB5757',
    semaphoreYellow: '#FFD851',
    semaphoreGreen: '#0CB64A',
    
    gpsReference0: '#0CB64A',
    gpsReference1: '#F0D472',
    gpsReference2: '#F0D472',
    gpsReference3: '#f1c232',
    gpsReference4: '#FB5757',

};